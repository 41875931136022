import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { BaseTheme } from '@boystownorg/bi-cms-component-lib';

const customPalette = {
  primary: {
    light2: '#1976D2',
    lightest: 'rgba(0, 85, 129, .3)',
  },
  tertiary: {
    main: '#00C4B3',
    light: '#04ffe9',
    dark: '#009d8f',
    mainLight: 'rgba(0, 196, 179, .1)',
  },
  secondary: {
    dark: '#B14520',
    light: 'rgb(0, 0, 0, 0.6)',
  },
  brandedAqua: {
    main: '#00C4B3',
    light: '#00C1B0',
    dark: '#00C4B3',
  },
  obsAqua: {
    main: '#54A8C6',
    light: '#54A8C6',
    dark: '#54A8C6',
  },
  obsBlue: {
    main: '#2F5279',
    light: '#F5F8FB',
    dark: '#2F5279',
  },
  obsPrevention: {
    main: '#1976D2',
    light: '#D1E4F6',
    zzlight: '#B3D1EF',
    dark: '#1976D2',
    xLight: '#F1F7FC',
  },
  obsPraise: {
    main: '#4AB984',
    light: '#DBF1E6',
    dark: '#4AB984',
    xLight: '#F8FCFA',
  },
  obsCorrection: {
    main: '#C75E57',
    light: '#F4DFDE',
    dark: '#C75E57',
    xLight: '#FDF9F8',
  },
  'obsOff Task': {
    main: '#DA682E',
    light: '#F0DBD2',
    dark: '#F0DBD2',
  },
  'obsSession Note': {
    main: 'rgba(75, 75, 75, 0.87)',
    light: 'rgba(0, 0, 0, 0.38)',
    dark: '#000000',
  },
  obsTimer: {
    main: '#FFFF00',
    light: '#FFF8DC',
    dark: '#DAA520',
  },
  warmRed: {
    main: '#CF4520',
  },
  text: {
    active: 'rgba(0, 0, 0, 0.54)',
    medium: '#656565',
    light: '#727272',
    dark: '#030303',
    mediumDark: '#3D3D3D',
    white: '#ffffff',
  },
  violet: {
    main: 'rgb(106 64 89)',
    dark: '#6A4059',
  },
  gold: {
    main: '#f4aa00',
    medium: '#f4aa00',
    light: '#fde7c6',
    dark: '#d49300',
    muted: '#CFAE4E',
  },
  white: {
    main: '#ffffff',
  },
  background: {
    lightGrey: '#F5F5F5',
  },
  softYellow: {
    main: '#ECCE98',
  },
  btLightGrey: {
    main: '#BFB8AF',
    dark: '#CDD7E1',
  },
};

//let theme = createTheme(BaseTheme);
let theme = createTheme(BaseTheme, {
  columnSize: {
    xs: '33.333%',
    sm: '66.66667%',
    md: '1140px',
    lg: '1536px',
    xl: '1920px',
  },
  palette: {
    ...BaseTheme.palette,
    ...customPalette,
  },
});
theme = responsiveFontSizes(theme);

export default theme;
